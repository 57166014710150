<template>
  <Dialog :commonDialog="dialog">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <v-form
        ref="product_import"
        v-model="valid_import"
        lazy-validation
        v-on:submit.stop.prevent="import_rows()"
      >
        <v-container fluid class="pt-0 my-4">
          <v-btn
            v-on:click="download_sample()"
            :disabled="loading_imported"
            class="mx-2 custom-grey-border custom-bold-button"
            color="cyan white--text"
            v-if="!sampleFile"
          >
            Download Sample
          </v-btn>
          <v-btn
            v-else
            class="mx-2 custom-grey-border custom-bold-button"
            color="cyan white--text"
            :href="$assetURL(sampleFile)"
            :disabled="loading_imported"
            target="_blank"
            download
            >Download Sample
          </v-btn>
          <v-file-input
            :disabled="loading_imported"
            :rules="[
              (value) =>
                !value ||
                value.size < 12000000 ||
                'File size should be less than 11 MB!',
            ]"
            v-on:change="handle_file_upload"
            placeholder="Select Excel File"
            color="cyan"
            show-size
            class="my-3"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ></v-file-input>

          <!-- <div class="import-success-message text-red">
            <ul>
              <li v-for="(message, index) in error_message_validation" :key="index">
                {{ message }}
              </li>
            </ul>
          </div> -->

          <table
            class="mt-5 font-weight-600 font-size-18 red--text text--lighten-1 w-100"
          >
            <tr>
              <td valign="top" align="right">Note:</td>
              <td valign="top" align="left">
                <em
                  >The first line in downloaded sample xlsx file should remain
                  as it is.<br />Please do not change the order of columns.</em
                >
              </td>
            </tr>
            <template v-if="backEndError">
              <tr v-for="(error, index) in backEndError" :key="index">
                <td valign="top" align="right"></td>
                <td>
                  {{ error }}
                </td>
              </tr>
            </template>
          </table>
        </v-container>
      </v-form>
      <table border="1" width="100%">
        <thead>
          <!--  <tr>
                <th></th>
                <th></th>
               </tr> -->
        </thead>
        <tbody>
          <tr v-for="(error, i) in newerrors" :key="i">
            <td>
              <span class="font-size-16 font-weight-500 text-danger">{{
                error[0]
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-slot:action>
      <v-btn
        v-if="!excel_imported"
        :disabled="!valid_import || loading_imported"
        class="mx-2 custom-grey-border custom-bold-button"
        color="cyan white--text"
        v-on:click="import_rows()"
      >
        Import
      </v-btn>
      <v-btn
        :disabled="loading_imported"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="closeEvent()"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import JwtService from "@/core/services/jwt.service";
import { POST } from "@/core/services/store/request.module";
import { ErrorEventBus, ClearEventBus } from "@/core/lib/message.lib";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { map } from "lodash";

export default {
  props: {
    sampleFile: {
      type: String,
      default: null,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
      default: null,
    },
    sampleUrl: {
      type: String,
      required: false,
      default: null,
    },
    apiUrl: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      backEndError: [],
      newerrors: "",
      valid_import: true,
      excel_imported: false,
      loading_imported: false,
      success_message: [],
      error_message: [],
    };
  },
  methods: {
    closeEvent() {
      this.$emit("close", true);
      this.backEndError = [];
    },
    handle_file_upload(param) {
      //console.log(param, "param");
      if (!param) {
        this.newerrors = null;
      }
      this.file = param;
      this.excel_imported = false;
      this.success_message = [];
      this.error_message = [];
    },
    download_sample() {
      const token = JwtService.getToken();
      const url = this.$apiURL + this.sampleUrl + "?token=" + token;
      window.open(url, "_target");
    },
    import_rows() {
      this.backEndError = [];
      const _this = this;

      const file = _this.file;

      ClearEventBus.$emit("clear:success", true);

      ClearEventBus.$emit("clear:error", true);

      if (!file) {
        ErrorEventBus.$emit("update:error", "Please select a valid file.");
        return false;
      }

      _this.loading_imported = true;
      _this.excel_imported = false;
      _this.success_message = [];
      _this.error_message = [];

      const data = new FormData();

      data.append("excel", file);

      _this.$store
        .dispatch(POST, { url: this.apiUrl, data })
        /* .then(({ data }) => {
          console.log(data.status,"status");
          _this.success_message = data.success || [];
          _this.error_message_validation = data.data || [];
          console.log(_this.error_message_validation,"_this.error_message_validation");
          _this.error_message = data.error || [];
          _this.excel_imported = true;
          if(data.status){
             ServiceFormEventBus.$emit("reload:staffdata");
           _this.$emit('close', true);
          }
         
             
        }) */
        .then((response) => {
          if (response.data.status === false) {
            const ErrorData = map(response.data.data, (row) => row);
            // this.$refs.product_import.reset();
            if (ErrorData) {
              _this.newerrors = ErrorData;
              return false;
            }
          }
          ServiceFormEventBus.$emit("reload:staffdata");
          _this.$emit("close", true);
          let data = response.data;

          ServiceFormEventBus.$emit("reload:staffdata");
          _this.$emit("close", true);
          _this.success_message = data.success || [];
          _this.error_message = data.error || [];
          _this.excel_imported = true;
          this.$refs.product_import.reset();

          //reload data
          if (_this.apiUrl == "equipment/import") {
            _this.$emit("successExcel", true);
          }

          // this.file = null;
        })

        .catch((error) => {
          if (
            error?.data?.excel_message?.excel_errros &&
            error?.data?.excel_message?.excel_errros.length
          ) {
            this.backEndError = error?.data?.excel_message?.excel_errros;
          } else {
            _this.logError(error);
          }
        })
        .finally(() => {
          _this.loading_imported = false;
        });
    },
  },
  components: {
    Dialog,
  },
};
</script>
